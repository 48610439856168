import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import TherapyVerificator from '../../../TherapyInformation/TherapyVerificator/TherapyVerificator';
import {unsetPZN} from '../../../../../state/actions/userManagement';

import PZN from './PZN';

import {
	BGIconStyled,
	ButtonStyled,
	HeadlineStyled,
	SubHeadlineStyled,
	PZNContainer,
	BackgroundIcon,
	MSTherapieTab,
	ToolTextStyled,
	ForcedFormStyler
} from './styles';
import Column from '../../../../Layout/Grid/Column';
import Row from '../../../../Layout/Grid/Row';

const NoPZN = () => (
	<>
		<PZNContainer>
			<SubHeadlineStyled>PZN-Verifizierung</SubHeadlineStyled>
			<ToolTextStyled content="<p>Um Zugang zu umfassenden Produktinformationen und zusätzlichen Services zu erhalten, ist die Eingabe einer Pharmazentralnummer (PZN) erforderlich.</p>" />
			<ForcedFormStyler>
				<TherapyVerificator />
			</ForcedFormStyler>
		</PZNContainer>
	</>
);

const MSTherapie = props => {
	const {pzn, handleReset} = props;
	return (
		<MSTherapieTab>
			<Row styles={{alignItems: 'center'}}>
				<Column lg={6}>
					<HeadlineStyled>Meine MS Therapie</HeadlineStyled>
				</Column>
				<Column lg={6}>
					{pzn && <ButtonStyled size="small" text="MS Therapie wechseln" onClick={() => handleReset()} />}
				</Column>
			</Row>
			{pzn ? (
				<PZN pzn={pzn} />
			) : (
				<>
					<NoPZN />
					<BackgroundIcon>
						<BGIconStyled name="therapie" height="28em" width="28em" type="teaser" />
					</BackgroundIcon>
				</>
			)}
		</MSTherapieTab>
	);
};

MSTherapie.propTypes = {
	pzn: PropTypes.shape({name: PropTypes.string, slug: PropTypes.string}),
	handleReset: PropTypes.func
};

MSTherapie.defaultProps = {
	pzn: null,
	handleReset: null
};

const mapStateToProps = state => ({
	pzn: state.userManagement.pzn
});

const mapDispatchToProps = dispatch => ({
	handleReset: () => dispatch(unsetPZN())
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(MSTherapie);
