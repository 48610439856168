import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import Banderole from 'assets/img/begleitprogramm/unregistered/banderole-2x.png';
import Corner from 'assets/img/begleitprogramm/unregistered/img-corner.png';

import {setGemeinsam} from '../../../../../state/actions/userManagement';
import Button from '../../../../Elements/Button/Button';
import {
	LeftBlock,
	TitleContainer,
	TextStyled,
	ContentStyled,
	TextBlock,
	ButtonRow,
	ContentContainer,
	StyledImage,
	StyledBanderole,
	UnregisteredStyled,
	ArrowContainer,
	StyledArrow,
	StyledCursor,
} from './styles';

import {TitleStyled} from '../../styles';
import Begleitprogramm from '../../Begleitprogramm/Begleitprogramm';

const Registered = () => <Begleitprogramm />;

const Unregistered = () => {
	return (
		<UnregisteredStyled>
			<ContentContainer>
				<StyledImage src={Corner} />
				<StyledBanderole src={Banderole} />
				<LeftBlock>
					<TitleContainer>
						<TitleStyled>Neue Impulse für dein Leben mit MS</TitleStyled>
					</TitleContainer>
					<TextBlock>
						<TextStyled content="Mit den 360°-Services erhältst du umfassende Unterstützung bei deiner MS, deinem Leben und deiner Behandlung. Das <strong>MS-Begleitprogramm</strong> ist Teil dieser kostenfreien Services*. Dein persönlicher MS-Coach gibt dir neue Impulse für dein Leben und hilft dir, deine Behandlung besser kennenzulernen und anzuwenden. Du legst fest, welches Coaching du in Anspruch nimmst. Du allein bestimmst, über welche Themen du sprichst und wie oft." />
						<TextStyled
							styles={{fontSize: '1.2rem', fontWeight: 'bold'}}
							content="<span style='color: red;'>Deine Anmeldung:</span> schnell und einfach – mit nur einem Klick"
						/>
						<TextStyled content="Hol dir deine persönliche Unterstützung." />
						<ArrowContainer>
							<StyledCursor />
							<StyledArrow />
						</ArrowContainer>
					</TextBlock>

					<ButtonRow>
						<Button to="/service/gemeinsam-stark#autoenroller" text="Jetzt anmelden" type="link" />
					</ButtonRow>

					<TextStyled
						styles={{fontSize: '0.8rem', marginTop: '3rem'}}
						content="*Für Patient*innen, denen ein verlaufsmodifizierendes Biogen Medikament verordnet wurde"
					/>
				</LeftBlock>
			</ContentContainer>
		</UnregisteredStyled>
	);
};

const GemeinsamStarkRaw = (props) => {
	// const [toggle, setToggle] = useState(false);
	// const {handleSubmit, user} = props;
	// let registered = false;

	// if (user && user.PatientProgramStatus.length > 0) {
	// 	registered = true;
	// }

	return (
		<>
			<ContentStyled>
				{/* Currently every user has access to psp via dashboard */}
				<Registered />

				{/* {toggle || registered ? (
					<Registered />
				) : (
					<Unregistered test="test" user={user} setToggle={setToggle} handleSubmit={handleSubmit} />
				)} */}
			</ContentStyled>
		</>
	);
};

Unregistered.propTypes = {
	user: PropTypes.shape({
		UserId: PropTypes.string,
	}),
};

GemeinsamStarkRaw.propTypes = {
	handleSubmit: PropTypes.func,
	user: PropTypes.shape({
		PatientProgramStatus: PropTypes.arrayOf,
	}),
};

GemeinsamStarkRaw.defaultProps = {
	handleSubmit: null,
	user: null,
};

const mapDispatchToProps = (dispatch) => ({
	handleSubmit: (data) => dispatch(setGemeinsam(data)),
});

const mapStateToProps = (state) => ({
	gemeinsam: state.userManagement.gemeinsam,
	user: state.userManagement.user,
});

export const GemeinsamStark = connect(mapStateToProps, mapDispatchToProps)(GemeinsamStarkRaw);
