import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {graphql, navigate} from 'gatsby';
import {connect} from 'react-redux';

import Layout from '../components/Layout/Layout';
import Meta from '../components/Layout/Meta';

import Dashboard from '../components/Container/Dashboard/Dashboard';
import AgreementForm from '../components/Container/AgreementForm';
import {PageTemplateStyled, PageSectionStyled} from '../templates/styles';

const DashboardPage = ({location, data, user}) => {
	const {edges: posts} = data.allWordpressPost;

	useEffect(() => {
		if (!user) {
			navigate('/login');
		}
	});

	return (
		<>
			<Meta />
			<Layout location={location}>
				<PageTemplateStyled>
					{user?.Agreement ? (
						<PageSectionStyled theme="grey">
							<Dashboard posts={posts} location={location} />
						</PageSectionStyled>
					) : (
						user && <AgreementForm />
					)}
				</PageTemplateStyled>
			</Layout>
		</>
	);
};

DashboardPage.propTypes = {
	location: PropTypes.shape({key: PropTypes.string}).isRequired,
	data: PropTypes.objectOf(PropTypes.object).isRequired,
	user: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
};

const mapStateToProps = (state) => ({
	user: state.userManagement.user,
});

export default connect(mapStateToProps)(DashboardPage);

export const pageQuery = graphql`
	query {
		allWordpressPost(sort: {fields: [date], order: DESC}) {
			edges {
				node {
					id
					title
					excerpt
					wordpress_id
					primary_category {
						name
					}
					acf {
						hidden
						persona
					}
					categories {
						id
						name
					}
					date(formatString: "MMMM DD, YYYY")
					slug
					path
					featured_media {
						localFile {
							childImageSharp {
								id
								fluid(srcSetBreakpoints: [800, 250], quality: 80) {
									...GatsbyImageSharpFluid_noBase64
								}
							}
						}
					}
				}
			}
		}
	}
`;
