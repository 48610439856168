import styled from 'styled-components';
import {media} from '../../../../styles/utils';
import {Icon} from '../../../Elements/Icon/Icon';
import Image from '../../../Elements/Image/Image';

import IcoPlus from '../../../../assets/img/icons/ico-plus.svg';
import Link from '../../../Elements/Link/Link';

export const BegleitprogrammStyled = styled.div`
	position: relative;
	width: 100%;
	margin-top: 1em;
	background: white;
`;

export const ContentWrapper = styled.div`
	padding: 1rem;
	display: flex;
	flex-direction: column;
	align-items: flex-start;

	${media.md`
		align-items: center;
	`}
`;

export const TopContent = styled.div`
	width: 100%;

	${media.md`
		width: 50%;
		margin: 0 auto;
		padding-left: 20%;
	`}
`;

export const StyledImage = styled(Image)`
	left: 0;
	top: 0;

	${media.md`
		position: absolute;
		max-width: 30%;

		img {
			width: unset !important;
			max-width: 100%;
			max-height: 20rem;
		}
	`}
`;

export const StyledBanderole = styled(Image)`
	position: absolute;
	right: -0.7rem;
	top: -0.55rem;
	max-width: 12rem;

	${media.md`
		max-width: 30%;
	`}

	${media.xl`
		right: -0.9rem;
		top: -0.75rem;
	`}

	${media.xxxl`
	right: -1.2rem;
    top: -0.9rem;
	`}
`;

export const StyledWeeklyPlan = styled(Image)`
	display: none;
	position: absolute;
	right: -8.3%;
	bottom: 0.5rem;
	max-width: 30%;

	${media.md`
		display: block;
	`}
`;

export const List = styled.ul``;

export const ListElement = styled.li`
	margin: 1rem;
	margin-left: 2rem;

	&::before {
		content: '';
		background: url(${IcoPlus}) no-repeat;
		background-size: contain;
		position: absolute;
		width: 1rem;
		height: 1rem;
		margin-left: -2rem;
		margin-top: 0.2rem;
	}
`;

export const Divider = styled.div`
	display: none;
	height: 1px;
	width: 60%;
	background: orange;
	margin-top: 1rem;
	margin-bottom: 2rem;

	${media.md`
		display: block;
	`}
`;

export const Service = styled.div`
	display: flex;
	flex-direction: column;

	${media.md`
		flex-direction: row;
		justify-content: center;
	`}

	${media.xxl`
		margin-left: 4rem;
	`}
`;

export const LinkStyled = styled(Link)`
	display: flex;
	align-items: center;
	margin: 1em 0;
	padding: 0;
	text-align: left;

	${media.md`
		max-width: 30%;
		margin: 1em 1em;
	`}
`;

export const ServiceItem = styled.div`
	display: flex;
`;

export const ServiceIcon = styled(Icon)`
	display: flex;
`;
