import React from 'react';

import {
	BegleitprogrammStyled,
	ContentWrapper,
	StyledImage,
	StyledBanderole,
	StyledWeeklyPlan,
	List,
	ListElement,
	Divider,
	Service,
	LinkStyled,
	TopContent,
} from './styles';

import Banderole from '../../../../assets/img/begleitprogramm/Banderole.png';
import Corner from '../../../../assets/img/begleitprogramm/img-corner-2.png';
import WeeklyPlan from '../../../../assets/img/begleitprogramm/ico-wochenplan.png';
import Button from '../../../Elements/Button/Button';

import Headline from '../../../Elements/Headline/Headline';
import IconButton from '../../../Elements/IconButton/IconButton';
import {Icon} from '../../../Elements/Icon/Icon';

const BegleitProgramm = () => {
	return (
		<BegleitprogrammStyled>
			<StyledWeeklyPlan src={WeeklyPlan} />
			<StyledImage src={Corner} />
			<StyledBanderole src={Banderole} />

			<ContentWrapper>
				<TopContent>
					<Headline as="h2" text="Digitaler ErnährungsManager" />

					<List>
						<ListElement>Erhalte umfassendes Wissen zur optimalen MS-bezogenen Ernährung</ListElement>
						<ListElement>Erstelle wöchentliche Ernährungspläne mit umfangreicher Rezeptauswahl</ListElement>
					</List>
					<Button type="link" to="/nutritionmanager/" text="ErnährungsManager testen" />
				</TopContent>
				<Divider />

				<Service>
					<LinkStyled to="/service/service-team/">
						<IconButton text="MS Service-Center anrufen">
							<Icon name="phone" />
						</IconButton>
					</LinkStyled>
					<LinkStyled to="mailto:info@ms-service-center.de">
						<IconButton text="E-Mail an das MS Service-Center verfassen">
							<Icon name="message" />
						</IconButton>
					</LinkStyled>
					<LinkStyled to="https://www.cleo-app.de/">
						<IconButton text="Cleo App benutzen">
							<Icon name="cleo" />
						</IconButton>
					</LinkStyled>
				</Service>
			</ContentWrapper>
		</BegleitprogrammStyled>
	);
};

BegleitProgramm.propTypes = {};

BegleitProgramm.defaultProps = {};

export default BegleitProgramm;
