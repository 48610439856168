import React from 'react';
import {connect} from 'react-redux';
import Headline from '../../../../Elements/Headline/Headline';
import TabsOnUserdata from './components/TabsOnUserdata';
import {StyledTabLeft} from './styles';
import AccessData from './SubForms/AccessData/AccessData';
import AddressData from './SubForms/AddressData/AddressData';
import PersonalData from './SubForms/PersonalData/PersonalData';
import SelfDescriptionData from './SubForms/SelfDescriptionData/SelfDescriptionData';
import {Icon} from '../../../../Elements/Icon/Icon';
import IconButton from '../../../../Elements/IconButton/IconButton';
import {userDataNotify} from '../../../../../state/actions/userManagement';

const tabItems = [
	{label: 'Persönliche Daten', icon: 'user'},
	{label: 'Adressen', icon: 'adresse'},
	{label: 'Zugangs&shy;daten', icon: 'login'},
	{label: 'Meine Angaben', icon: 'angaben'},
].map((item) => (
	<StyledTabLeft key={item.label}>
		<IconButton text={item.label}>
			<Icon name={item.icon} />
		</IconButton>
	</StyledTabLeft>
));

const UserDataInDashboard = (props) => {
	return (
		<>
			<Headline as="h2" text="Meine Daten" />
			<TabsOnUserdata tabItems={tabItems} state={props.target}>
				<PersonalData />
				<AddressData />
				<AccessData />
				<SelfDescriptionData />
			</TabsOnUserdata>
		</>
	);
};

const mapDispatchToProps = (dispatch) => ({
	notifyTabChangeProhibited: (message) => dispatch(userDataNotify(message, false)),
});

export default connect(null, mapDispatchToProps)(UserDataInDashboard);
