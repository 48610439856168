import React from 'react';
import * as Yup from 'yup';
import {connect} from 'react-redux';
import {userDataUpdate} from '../../../../../../../state/actions/userManagement';
import {ButtonAreaStyled} from '../../styles';

import PropTypes from 'prop-types';
import SubmitForm from '../../../../../SubmitForm/SubmitForm';
import Button from '../../../../../../Elements/Button/Button';

import {withAsterisk, overwriteInitialValues} from '../../../../../../../utils/formHelpers';
import {SectionStyled} from '../../styles';
import Column from '../../../../../../Layout/Grid/Column';
import {Input} from '../../../../../../Elements/Form/InputAndTextarea/InputAndTextarea';
import FormRow from '../../../../../../Elements/Form/FormRow/FormRow';
import {forms} from '../../../../../../../assets/language/de';
import {emailValidation} from '../../../../../../../utils/validations';

const initialValuesForAddressData = {
	Email: '',
};
const {label, validationMessages} = forms.Register;

const validationSchema = Yup.object().shape({
	Email: emailValidation(validationMessages.email.ifEmpty, validationMessages.email.ifNotValid),
});

const AddressData = ({onSubmit, user}) => (
	<SubmitForm
		initialValues={overwriteInitialValues(initialValuesForAddressData, user)}
		validationSchema={validationSchema}
		onSubmit={onSubmit}
		renderInnerForm={(formikProps) => (
			<>
				<SectionStyled>
					<FormRow>
						<Column lg={10}>
							<Input label={withAsterisk(label.email)} name="Email" />
						</Column>
					</FormRow>
				</SectionStyled>
				<ButtonAreaStyled>
					<Button isSubmitting={formikProps.isSubmitting} type="submit" text="Zum Profil speichern" />
				</ButtonAreaStyled>
			</>
		)}
	/>
);
AddressData.propTypes = {
	user: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
	onSubmit: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
	user: state.userManagement && state.userManagement.user,
});

const mapDispatchToProps = (dispatch) => ({
	onSubmit: (data) => dispatch(userDataUpdate(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddressData);
