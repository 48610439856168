import React from 'react';
import PropTypes from 'prop-types';
import Row from '../../../../../Layout/Grid/Row';
import Column from '../../../../../Layout/Grid/Column';
import TabSelection from '../../../../TabsSelection/TabSelection';
import {ObserveModificationConsumer} from '../../../../../../context/observeModificationContext';
import {PersonalDataTab} from '../styles';

/**
 * @component TabsOnUserdata, shows tabs for selection, and the selected content
 * @arg tabItems: list of components that form the tabs, which can be selected
 * @arg children: components that form the content, only one of which is shown depending of selected tabItem
 */
const TabsOnUserdata = ({tabItems, children, state}) => {
	let indexSelected = 0;
	if (state) {
		indexSelected = state;
	}
	return (
		<ObserveModificationConsumer>
			{(context) => (
				<TabSelection
					bodyItems={React.Children.toArray(children)}
					onTabChangeRequest={context.onTabChangeRequest}
					tabItems={tabItems}
					indexSelected={indexSelected}
					render={(selectedTabItems, selectedBodyItem) => (
						<Row>
							<Column lg={3}>
								<PersonalDataTab>
									{selectedTabItems.map((tabItem, _i) => (
										<li key={_i}>{tabItem}</li>
									))}
								</PersonalDataTab>
							</Column>
							<Column lg={9}>{selectedBodyItem}</Column>
						</Row>
					)}
				/>
			)}
		</ObserveModificationConsumer>
	);
};

TabsOnUserdata.propTypes = {
	children: PropTypes.node,
	onTabChangeRequest: PropTypes.func, // returns true if tab change is allowed, can do i.e. some testing or show user feedback
	tabItems: PropTypes.arrayOf(PropTypes.element),
};

TabsOnUserdata.defaultProps = {
	onTabChangeRequest: () => true,
	tabItems: undefined,
	children: null,
};

export default TabsOnUserdata;
