import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Headline from '../../Elements/Headline/Headline';
import {
	IconStyled,
	DashboardWrapper,
	LogoutDashboardStyled,
	TabTitle,
	TabOnDashboard,
	HeaderStyled,
	PostListContainer,
	WrapperForSelectedItem,
} from './styles';
import LogoutButton from '../Login/LogoutButton/LogoutButton';
import MeineDaten from './Tabs/MeineDaten/MeineDaten';

import {GemeinsamStark} from './Tabs/GemeinsamStark/GemeinsamStark';
import MSTherapie from './Tabs/MSTherapie/MSTherapie';
import {MSCommunity} from './Tabs/MSCommunity/MSCommunity';
import PostList from '../Stream/PostList/Container';
import TabsOnDashboard from './TabsOnDashboard';
import StateHandlerFormModifications from './StateHandlerFormModifications';
import {clearErrors, userDataNotify} from '../../../state/actions/userManagement';

const messageTabChangeProhibited =
	'Wenn du die Seite verlässt, gehen alle eingegebenen Änderungen verloren! Bitte klicke auf speichern, um die Änderungen zu übernehmen.'; // todo: wording; put this into file './constants.js'

const Dashboard = (props) => {
	const {notifyTabChangeProhibited, removeNotification, user, posts, location} = props;

	const tabItems = [
		{name: 'MS-Begleitprogramm', icon: 'gemeinsam', size: 'teaser'},
		{name: 'MS-Behandlung', icon: 'therapie', size: 'teaser'},
		{name: 'msCommunity', icon: 'community', size: 'teaser'},
		{name: 'Persönliche Daten', icon: 'profil', size: 'teaser'},
	].map((labelString) => (
		<TabOnDashboard key={labelString.name}>
			<IconStyled name={labelString.icon} type={labelString.size} />
			<TabTitle>{labelString.name}</TabTitle>
		</TabOnDashboard>
	));

	return (
		<DashboardWrapper>
			<HeaderStyled>
				<LogoutDashboardStyled>
					<LogoutButton
						text="Abmelden"
						height="1em"
						width="1em"
						className="dashboard__logout"
						theme="candy"
					/>
				</LogoutDashboardStyled>
				{user && <Headline as="h1" text={`Hallo ${user.FirstName}!`} />}
				<Headline as="h5" text="<em>Hier ist dein persönlicher Bereich</em>" />
			</HeaderStyled>

			<StateHandlerFormModifications
				notifyTabChangeProhibited={notifyTabChangeProhibited}
				removeNotification={removeNotification}>
				<TabsOnDashboard tabItems={tabItems} state={location.state}>
					<GemeinsamStark />
					<WrapperForSelectedItem>
						<MSTherapie />
					</WrapperForSelectedItem>
					<WrapperForSelectedItem>
						<MSCommunity />
					</WrapperForSelectedItem>
					<WrapperForSelectedItem>
						<MeineDaten target={location.state && location.state.targetSelected} />
					</WrapperForSelectedItem>
				</TabsOnDashboard>
			</StateHandlerFormModifications>

			<PostListContainer>
				<PostList posts={posts} title="Das könnte dich auch interessieren" section="interestTeaserList" />
			</PostListContainer>
			<PostListContainer>
				<PostList posts={posts} title="Gemerkte Artikel" section="noticedTeaserList" />
			</PostListContainer>
			<PostListContainer>
				<PostList posts={posts} title="Empfohlene Artikel" section="recommendedTeaserList" />
			</PostListContainer>
		</DashboardWrapper>
	);
};

Dashboard.propTypes = {
	notifyTabChangeProhibited: PropTypes.func.isRequired,
	removeNotification: PropTypes.func.isRequired,
	user: PropTypes.shape({FirstName: PropTypes.string}),
	location: PropTypes.shape(PropTypes.any),
	posts: PropTypes.arrayOf(PropTypes.object),
};

Dashboard.defaultProps = {
	user: null,
	posts: null,
	location: null,
};

const mapStateToProps = (state) => ({
	user: state.userManagement.user,
});

const mapDispatchToProps = (dispatch) => ({
	notifyTabChangeProhibited: () => dispatch(userDataNotify(messageTabChangeProhibited, false)),
	removeNotification: () => dispatch(clearErrors()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
