import styled from 'styled-components';
import {media} from '../../../../../styles/utils';
import Link from '../../../../Elements/Link/Link';
import Section from '../../../../Layout/Section/Section';

export const SectionStyled = styled(Section)`
	width: 100%;
`;

export const LogoContainer = styled.div`
	margin-bottom: 1.5em;
`;

export const LeftBlock = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-around;
	flex-direction: column;
	width: 100%;
`;
export const RightBlock = styled.div`
	min-width: 30%;
	display: flex;
	flex-direction: column;
	justify-content: center;

	${media.xl`
		margin-left: 3em;
	`}
`;

export const Container = styled.div`
	min-height: 15em;
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;

	${media.xl`
		flex-direction: row;
	`}
`;

export const LinkStyled = styled(Link)`
	display: flex;
	align-items: center;
	margin-bottom: 1em;
	padding: 0;
	text-align: left;
`;
