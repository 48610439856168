import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import SubmitForm from '../../../../../SubmitForm/SubmitForm';
import {ColumnStyled, RowStyled, SubHeadlineStyled, ButtonAreaStyled} from '../../styles';
import Dropdown from '../../../../../../Elements/Form/Dropdown/Dropdown';
import {optionsHowLongAnIssue, optionsOfBeingAffected} from '../../../../../NLF/constants';
import {setNLF} from '../../../../../../../state/actions/userManagement';
import Button from '../../../../../../Elements/Button/Button';
import {nlfOptions} from "../../../../../../../utils/nlfUtils";

const optionsOfBeingAffectedTranslated = optionsOfBeingAffected.map(({label, value}) => ({label, type: value}));
const optionsHowLongAnIssueTranslated = optionsHowLongAnIssue.map(({label, value}) => ({label, type: value}));

const SelfDescriptionData = props => {
	const {user, userManagement, submitNewData} = props;

	if (!userManagement.user) {
		return null;
	}

	const nlfValue = parseInt(userManagement.user.nlf, 10);
	// local values
	const nlfLocalVal1 = 0;
	const nlfLocalVal2 = 0;

	// database based values
	const nlfValue1 = 0;
	const nlfValue2 = 0;

	let finVal1 = 0;
	let finVal2 = 0;
	// nlf had not yet been saved to database - use local redux state when available
	if (nlfValue === 0 || nlfValue1 === null) {
		if (userManagement.nlfObject.nlfVal1) {
			finVal1 = userManagement.nlfObject.nlfVal1.value;
		}
		if (userManagement.nlfObject.nlfVal2) {
			finVal2 = userManagement.nlfObject.nlfVal2.value;
		}
	} else if (nlfValue !== 0) {
		finVal1 = nlfValue % 2 > 0 ? 1 : 2;
		finVal2 = nlfValue - 14 > 2 ? 8 : 4;
	}

	let newValue = null;

	const postNLFData = nlfValue => {
		fetch(`${process.env.GATSBY_UMS_URL}/userdata`, {
			method: 'POST',
			mode: 'cors',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				user_id: user.UserId,
				nlf: nlfValue
			})
		});
	};

	function submitData(data) {
		let dataAvailable = false;
		if (data.optionsOfBeingAffected && data.optionsHowLongAnIssue) {
			dataAvailable = true;
		}
		window.dataLayer.push({
			event: 'biib_bot',
			biib_botAnswer1: nlfOptions[data.optionsOfBeingAffected],
			biib_botAnswer2: nlfOptions[data.optionsHowLongAnIssue]
		});
		newValue = data.optionsOfBeingAffected + data.optionsHowLongAnIssue + 10;
		if (newValue !== nlfValue && dataAvailable === true) {
			postNLFData(newValue);
			submitNewData(newValue);
		}
	}

	const reset = resetForm => {
		const initValues = {
			optionsOfBeingAffected: 0,
			optionsHowLongAnIssue: 0
		};
		resetForm(initValues);
		postNLFData(0);
		submitNewData('0');
	};

	return (
		<>
			<SubmitForm
				initialValues={{
					optionsOfBeingAffected: finVal1,
					optionsHowLongAnIssue: finVal2
				}}
				onSubmit={submitData}
				renderInnerForm={formikProps => (
					<>
						<SubHeadlineStyled>Meine Angaben</SubHeadlineStyled>
						<RowStyled>
							<ColumnStyled md={6}>
								<Dropdown
									label="Ich bin:"
									items={optionsOfBeingAffectedTranslated}
									name="optionsOfBeingAffected"
								/>
							</ColumnStyled>
						</RowStyled>
						<RowStyled>
							<ColumnStyled md={6}>
								<Dropdown
									label="Und die Krankheit ist für mich:"
									items={optionsHowLongAnIssueTranslated}
									name="optionsHowLongAnIssue"
								/>
							</ColumnStyled>
						</RowStyled>
						<RowStyled>
							<ButtonAreaStyled>
								<Button
									type="button"
									onClick={reset.bind(null, formikProps.resetForm)}
									text="Meine Angaben löschen"
								/>
								<Button type="submit" text="Zum Profil speichern" />
							</ButtonAreaStyled>
						</RowStyled>
					</>
				)}
			/>
		</>
	);
};

const mapStateToProps = state => ({
	user: state.userManagement && state.userManagement.user,
	userManagement: state.userManagement
});

const mapDispatchToProps = dispatch => ({
	submitNewData: newValue => dispatch(setNLF(newValue)) // TODO later : handle submit data like in NLF.js (i.e. setNLF())
});

SelfDescriptionData.propTypes = {
	user: PropTypes.shape({
		nlf: PropTypes.number,
		UserId: PropTypes.number.isRequired
	}),
	nlf: PropTypes.number,
	submitNewData: PropTypes.func
};

SelfDescriptionData.defaultProps = {
	user: undefined,
	submitNewData: undefined,
	nlf: 0
};

export default connect(mapStateToProps, mapDispatchToProps)(SelfDescriptionData);
