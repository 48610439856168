import styled from 'styled-components';
import {colors, sizes} from '../../../../../styles/variables';
import Row from '../../../../Layout/Grid/Row';
import Column from '../../../../Layout/Grid/Column';
import {withUnit, media} from '../../../../../styles/utils';
import {HeadlineStyledH3} from '../../../../Elements/Headline/styles';
import Section from '../../../../Layout/Section/Section';

export const SubHeadlineStyled = styled(HeadlineStyledH3)`
	margin-bottom: ${withUnit(sizes.distance.base)}!important;
`;

export const ButtonAreaStyled = styled(Column)`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	${media.lg`
		flex-direction: row;
	`}	
`;

export const ColumnStyled = styled(Column)`
	padding-bottom: 1em;
`;

export const RowStyled = styled(Row)`
	box-sizing: border-box;
	display: flex;
	flex: 0 1 auto;
	margin: 0 -0.5em ${withUnit(sizes.distance.base / 2)} -0.5em;
	flex-wrap: wrap;
`;

export const PersonalDataTab = styled.ul`
	padding: 0;
	margin: 0;
	list-style: none;
	display: flex;

	> li {
		flex: 0 1 25%;
		display: flex;
		justify-content: center;
		align-items: flex-start;
	}

	${media.lg`
		display: block;
		
		> li {
			display: block;
		}
	`}
`;

export const StyledTabLeft = styled.div`
	position: relative;
	cursor: pointer;
	display: flex;
	align-items: center;
	padding: 1em 0;

	div {
		color: ${({isSelected}) => (isSelected ? colors.black : colors.silver)};
	}

	button {
		background: ${({isSelected}) => (isSelected ? '' : colors.silver)};
	}

	.ap-iconbutton-container {
		flex-direction: column;
		font-size: 0.7em;
		text-align: center;

		${media.lg`
			flex-direction: row;
			font-size: 0.8em;
			text-align: left;
		`}

		${media.xl`
			font-size: 1em;
		`}
	}
`;

export const SectionStyled = styled(Section)`
	margin-bottom: ${withUnit(sizes.distance.base * 2)};
`;