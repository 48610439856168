import styled from 'styled-components';
import {colors, fonts, sizes} from '../../../styles/variables';
import {gradientCandyCarrot, media, withUnit} from '../../../styles/utils';
import {Icon} from '../../Elements/Icon/Icon';
import Section from '../../Layout/Section/Section';
import {HeadlineStyledH2} from '../../Elements/Headline/styles';
import Row from '../../Layout/Grid/Row';
import Column from '../../Layout/Grid/Column';

export const HeaderStyled = styled.div`
	position: relative;
	padding-bottom: 2rem;
	text-align: center;
`;

export const IconStyled = styled(Icon)`
	display: inline-block;

	svg {
		width: 2.5em;
		height: 2.5em;
		//fill: ${colors.navy};

		${media.md`
			display: block;
			width: 3em;
			height: 3em;
		`}

		${media.lg`
			display: none;
			width: 2em;
			height: 2em;
		`}

		${media.xl`
			width: 2em;
			height: 2em;
		`}

		${media.xxl`
			display: block;
			width: 3em;
			height: 3em;
		`}
	}
`;

export const PostListContainer = styled.div`
	margin: 3em 0;
`;

export const TabTitle = styled.div`
	display: none;
	font-weight: 300;
	width: 100%;
	text-align: center;
	font-family: ${fonts.serif};

	${media.lg`
		display: block;
		font-size: 0.7rem;
	`}

	${media.xl`
		display: block;
		font-size: 0.8rem;
	`}

	${media.xxl`
		font-size: 1rem;
	`}
`;

export const DashboardWrapper = styled(Section).attrs({showPadding: true})`
	${media.lg`
		width: 75%;
		margin: 0 auto;
	`}
`;

export const LogoutDashboardStyled = styled.div`
	display: none;
	position: absolute;
	right: 0;
	top: 0;

	${media.lg`
		display: block;
	`}
`;

export const TabContainerStyled = styled(Row)`
	display: flex;
	flex-wrap: nowrap;
`;

export const TabStyled = styled(Column)`
	flex: 0 1 25%;
	max-width: 25%;
`;

export const TabOnDashboard = styled.div`
	box-sizing: border-box;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	padding: 0.5em;
	font-family: ${fonts.serif};
	transition: box-shadow 0.3s ease;
	height: 100%;

	svg {
		color: ${({isSelected}) => (isSelected ? colors.white : colors.navy)};
	}

	${({isSelected}) =>
		isSelected
			? `
				box-shadow: 2px 2px 10px rgba(0,0,0,0.15);
				color: ${colors.white};
				${gradientCandyCarrot()};
				
				svg {
					fill: ${colors.white};
				}
			`
			: `
				background-color: ${colors.white};
				color: ${colors.black};
				
				svg {
					fill: ${colors.navy};
				}
			`};
`;

export const WrapperForSelectedItem = styled.div`
	position: relative;
	margin-top: 1em;
	min-height: 20em;
	background-color: white;
	padding: ${withUnit(sizes.distance.base / 2)};

	${media.lg`
		padding: ${withUnit(sizes.distance.base)};
	`}

	${media.xxl`
		padding: ${withUnit(sizes.distance.base * 2)};
	`}
`;

export const TitleStyled = styled(HeadlineStyledH2)`
	margin-top: 0;
`;
