import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import {ObserveModificationProvider} from '../../../context/observeModificationContext';

export default class StateHandlerFormModifications extends React.PureComponent {
	static propTypes = {
		notifyTabChangeProhibited: PropTypes.func,
		removeNotification: PropTypes.func,
		children: PropTypes.element.isRequired
	};

	static defaultProps = {
		notifyTabChangeProhibited: () => {},
		removeNotification: () => {}
	};

	state = {
		isModified: false
	};

	lastIdentifierOfNewTab = null;

	componentDidMount() {
		this.mounted = true;
	}

	componentWillUnmount() {
		this.mounted = false;
	}

	onModified = isModified => {
		if (this.mounted) {
			this.setState({isModified});
		}
	};

	onTabChangeRequest = identifierOfNewTab => {
		const {notifyTabChangeProhibited, removeNotification} = this.props;
		const {isModified} = this.state;

		// we allow tabChange when user clicks a second time on the same tab
		const secondTimeClickedOnTheSameTab =
			identifierOfNewTab === this.lastIdentifierOfNewTab && !_.isNil(identifierOfNewTab);
		const allowTabChange = !isModified || secondTimeClickedOnTheSameTab;

		if (allowTabChange) {
			removeNotification();
			this.onModified(false); // because we're on a different tab now
		} else {
			notifyTabChangeProhibited();
		}

		this.lastIdentifierOfNewTab = identifierOfNewTab;
		return allowTabChange; // allow tab change if isModified is false
	};

	render() {
		const {children} = this.props;
		const contextObject = {
			onModified: this.onModified,
			onTabChangeRequest: this.onTabChangeRequest
		};

		return <ObserveModificationProvider value={contextObject}>{children}</ObserveModificationProvider>;
	}
}
