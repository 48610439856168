import React from 'react';
import PropTypes from 'prop-types';

/** component */
export default class TabSelection extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			indexSelected: 0,
		};
	}
	componentDidMount() {
		this.setState({indexSelected: this.props.indexSelected});
	}
	static propTypes = {
		onTabChangeRequest: PropTypes.func, // returns true if tab change is allowed, can i.e. show user feedback or do some testing
	};

	static defaultProps = {
		onTabChangeRequest: () => true,
	};

	myIdentifier = `${Math.random()}`;

	onSelect(newIndex) {
		const {onTabChangeRequest} = this.props;
		const {indexSelected} = this.state;

		if (indexSelected !== newIndex) {
			const identifierOfTab = `${this.myIdentifier}_${newIndex}`;
			if (onTabChangeRequest(identifierOfTab)) {
				this.setState({indexSelected: newIndex});
			}
		}
	}

	selectedTabItems() {
		const {tabItems} = this.props;
		const {indexSelected} = this.state;
		return tabItems.map((item, index) =>
			React.cloneElement(item, {
				isSelected: index === indexSelected,
				onClick: () => this.onSelect(index),
			})
		);
	}

	selectedBodyItem() {
		const {bodyItems} = this.props;
		const {indexSelected} = this.state;
		return bodyItems[indexSelected || 0];
	}

	render() {
		const {render} = this.props;
		return render(this.selectedTabItems(), this.selectedBodyItem());
	}
}

TabSelection.propTypes = {
	render: PropTypes.func,
	tabItems: PropTypes.arrayOf(PropTypes.object),
	bodyItems: PropTypes.node,
};

TabSelection.defaultProps = {
	render: null,
	tabItems: undefined,
	bodyItems: undefined,
};
