import styled from 'styled-components';

import Text from 'components/Elements/Text/Text';
import Image from 'components/Elements/Image/Image';

import Arrow from 'assets/img/begleitprogramm/unregistered/arrow.png';
import ArrowMobile from 'assets/img/begleitprogramm/unregistered/arrow-mobile.png';
import Cursor from 'assets/img/begleitprogramm/unregistered/ico-click.png';

import {media} from '../../../../../styles/utils';
import Intro from '../../../../Elements/Intro/Intro';
import Link from '../../../../Elements/Link/Link';

import Button from '../../../../Elements/Button/Button';
import {Icon} from '../../../../Elements/Icon/Icon';
import {colors} from '../../../../../styles/variables';

export const ContentContainer = styled.div`
	display: block;

	${media.md`
		display: flex;
	`}
`;

export const IconStyled = styled(Icon)`
	display: block;
	transform: translate(4em, 4em);
`;

export const BackgroundIcon = styled.div`
	display: none;
	position: absolute;
	right: 0;
	bottom: 0;
	opacity: 0.15;
	overflow: hidden;
	pointer-events: none;

	${media.lg`
		display: block;
	`}

	svg {
		color: ${colors.grey};
	}
`;

export const ArrowContainer = styled.div`
	position: relative;
	height: 5rem;

	${media.md`
	height: 3rem;
	max-width: 24rem;
	`}
`;

export const StyledArrow = styled.div`
	background: url(${ArrowMobile});
	background-repeat: no-repeat;
	background-size: contain;
	position: absolute;
	top: 0;
	right: 0;
	width: 5rem;
	height: 5rem;

	${media.md`
	background: url(${Arrow});
	background-repeat: no-repeat;
	background-size: contain;
	width: 8rem;
	height: 8rem;
	`}
`;

export const StyledCursor = styled.div`
	background: url(${Cursor});
	background-repeat: no-repeat;
	background-size: contain;
	position: absolute;
	top: 1rem;
	right: 3rem;
	width: 4rem;
	height: 4rem;
`;

export const LinkStyled = styled(Link)`
	display: flex;
	align-items: center;
	margin: 1em 0;
	padding: 0;
	text-align: left;
`;

export const IntroStyled = styled(Intro)``;
export const TextStyled = styled(Text)`
	margin-bottom: 1rem !important;
`;

export const StyledImage = styled(Image)`
	right: 0;
	top: 0;
	width: 100%;

	${media.md`
		position: absolute;
		max-width: 34%;

		img {
			width: unset !important;
			max-width: 100%;
			max-height: 25rem;
			position: absolute;
			right: 0;
			top: 0;
		}
	`}
`;

export const StyledBanderole = styled(Image)`
	position: absolute;
	max-width: 15rem;
	right: -0.9rem;
	top: -1.2rem;

	${media.md`
	max-width: 10rem;
    right: -0.6rem;
    top: -0.8rem;
	`}

	${media.xl`
	max-width: 15rem;
    right: -0.9rem;
    top: -1.2rem;
	`}
`;

export const LeftBlock = styled.div`
	display: flex;
	flex-direction: column;
	padding: 1rem;

	${media.md`
		width: 62%;
		padding: 2rem;
		padding-bottom: 0.5rem;
	`}
`;

export const UnregisteredStyled = styled.div`
	position: relative;
	width: 100%;
	margin-top: 1em;
	background: white;
`;

export const TitleContainer = styled.div``;

export const ContentStyled = styled.div``;

export const ContactSectionStyled = styled.div`
	width: 100%;

	${media.xl`
		width: 65%;
	`}
`;

export const LinkContainer = styled.div`
	width: 100%;
	margin: 2em 0;
	${media.xl`
		margin: 0;
		width: 35%;
	`}
`;

export const TextBlock = styled.div`
	margin-right: 0;
	${media.xl`
		margin-right: 3em;
	`}
`;

export const ImageContainer = styled.div`
	height: 100%;
	width: 100%;
	margin: 0 auto;
	max-width: 400px;
`;

export const ButtonRow = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: start;

	${media.md`
		flex-direction: row;
	`}
`;

export const ButtonBlock = styled.div`
	display: flex;
	flex-direction: column;
`;

export const ButtonStyled = styled(Button)`
	margin-top: 0;

	&:not([disabled]):hover {
		transform: translateY(0);
		box-shadow: none;
	}

	span {
		padding-bottom: 0.3em;
		border-bottom: 2px solid ${colors.carrot};
	}

	&:hover,
	&:active,
	&:focus {
		color: ${colors.carrot};
	}
`;
