import React from 'react';
import PropTypes from 'prop-types';
import {withSize} from 'react-sizeme';

import {VectorBlockStyled} from './styles';

const VectorBlock = ({size, children}) => <VectorBlockStyled size={size}>{children}</VectorBlockStyled>;

VectorBlock.propTypes = {
	size: PropTypes.shape({width: PropTypes.number, height: PropTypes.number}),
	children: PropTypes.node
};

VectorBlock.defaultProps = {
	size: undefined,
	children: null
};

export default withSize()(VectorBlock);
