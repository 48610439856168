import React from 'react';
import {Icon} from '../../../../Elements/Icon/Icon';
import {LeftBlock, RightBlock, Container, LinkStyled, LogoContainer, SectionStyled} from './styles';
import LogoCommunity from '../../../../Layout/Logo/LogoCommunity';
import IconButton from '../../../../Elements/IconButton/IconButton';

import Intro from '../../../../Elements/Intro/Intro';

export const MSCommunity = () => (
	<Container>
		<LeftBlock>
			<LogoContainer>
				<LogoCommunity />
			</LogoContainer>
			<SectionStyled center>
				<Intro content="In der msCommunity kannst du anderen Betroffenen Fragen stellen und mit eigenen Erfahrungen helfen." />
			</SectionStyled>
		</LeftBlock>
		<RightBlock>
			<LinkStyled to="/ms-community/ucp.php" theme="white">
				<IconButton text="Dein msCommunity Profil">
					<Icon name="user" />
				</IconButton>
			</LinkStyled>
			<LinkStyled to="/ms-community/memberlist.php" theme="white">
				<IconButton text="msCommunity Mitglieder">
					<Icon name="member" />
				</IconButton>
			</LinkStyled>
			<LinkStyled to="/ms-community/ucp.php?i=pm&folder=inbox" theme="white">
				<IconButton text="Deine Nachrichten">
					<Icon name="message" />
				</IconButton>
			</LinkStyled>
			<LinkStyled to="/ms-community/ucp.php?i=ucp_notifications&mode=notification_list" theme="white">
				<IconButton text="Benachrichtigungen">
					<Icon name="note" />
				</IconButton>
			</LinkStyled>
			<LinkStyled to="/ms-community/ucp.php?i=179" theme="white">
				<IconButton text="msCommunity Einstellungen">
					<Icon name="options" />
				</IconButton>
			</LinkStyled>
		</RightBlock>
	</Container>
);
