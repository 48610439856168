import styled from 'styled-components';
import {colors} from '../../../../../styles/variables';
import {media} from '../../../../../styles/utils';
import {HeadlineStyledH2, HeadlineStyledH3} from '../../../../Elements/Headline/styles';
import Button from '../../../../Elements/Button/Button';
import IconInfobox from '../../../../Elements/IconInfobox/IconInfobox';
import {Icon} from '../../../../Elements/Icon/Icon';
import Text from '../../../../Elements/Text/Text';

export const ForcedFormStyler = styled.div``;

export const VectorApplicationContainer = styled.div`
	display: flex;
	margin-bottom: 1rem;
	align-items: center;
	img {
		height: 3rem;
	}
	p {
		padding: 0 0 0 1rem;
		margin: 0;
	}
`;

export const IconBoxStyled = styled(IconInfobox)`
	& {
		margin-bottom: 0;
	}
`;

export const MSTherapieTab = styled.div``;

export const ButtonStyled = styled(Button)`
	${media.lg`
		float: right;
	`}
`;

export const HeadlineStyled = styled(HeadlineStyledH2)``;

export const SubHeadlineStyled = styled(HeadlineStyledH3)``;

export const PZNContainer = styled.div`
	width: 90%;
	max-width: 700px;

	z-index: 1;
	position: relative;
`;

export const BGIconStyled = styled(Icon)`
	display: block;
	transform: translate(4em, 4em);
`;

export const BackgroundIcon = styled.div`
	display: none;
	position: absolute;
	right: 0;
	bottom: 0;
	opacity: 0.15;
	overflow: hidden;
	z-index: 0;
	pointer-events: none;

	${media.lg`
		display: block;
	`}

	svg {
		color: ${colors.grey};
	}
`;

export const ProductContainerStyled = styled.div`
	margin-left: -1.5em;
	margin-right: -1.5em;
	margin-top: -2em;
	margin-bottom: -2em;
`;

export const LeftBlock = styled.div`
	width: 50%;
`;

export const RightBlock = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;

	${media.lg`
	width: 50%;
	`}
`;

export const TextStyled = styled(Text)`
	font-weight: 600;

	p {
		margin: 0;
	}
`;

export const ToolTextStyled = styled(Text)`
	font-weight: 300;

	p {
		margin: 0;
	}
`;

export const LinkBlock = styled.div``;

export const ResetTherapyButton = styled(Button)`
	margin: 0;
`;

export const InfoContainer = styled.div``;
