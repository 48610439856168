import React from 'react';
import * as Yup from 'yup';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import {userDataUpdate} from '../../../../../../../state/actions/userManagement';
import SubmitForm from '../../../../../SubmitForm/SubmitForm';
import Button from '../../../../../../Elements/Button/Button';
import Column from '../../../../../../Layout/Grid/Column';
import {Input} from '../../../../../../Elements/Form/InputAndTextarea/InputAndTextarea';
import Checkbox from '../../../../../../Elements/Form/Checkbox/Checkbox';
import {SubHeadlineStyled, SectionStyled} from '../../../../../Register/styles';
import FormRow from '../../../../../../Elements/Form/FormRow/FormRow';
import {withAsterisk, overwriteInitialValues} from '../../../../../../../utils/formHelpers';
import {forms} from '../../../../../../../assets/language/de';
import {ButtonAreaStyled} from '../../styles';
import {newsletterText} from '../../../../../../../assets/language/privacyPolicy.lang';

const {label, validationMessages} = forms.Register;

const initialValuesForPersonalData = {
	FirstName: '',
	LastName: '',
	Newsletter: false,
};

const validationSchema = Yup.object().shape({
	FirstName: Yup.string().min(1, validationMessages.forename.ifEmpty),
	LastName: Yup.string().min(1, validationMessages.userName.ifEmpty),
});

const PersonalData = ({onSubmit, user}) => (
	<SubmitForm
		initialValues={overwriteInitialValues(initialValuesForPersonalData, user)}
		validationSchema={validationSchema}
		onSubmit={onSubmit}
		renderInnerForm={(formikProps) => (
			<>
				<SectionStyled>
					<SubHeadlineStyled>Persönliche Daten</SubHeadlineStyled>
					<FormRow>
						<Column lg={6}>
							<Input label={withAsterisk(label.forename)} name="FirstName" />
						</Column>
						<Column lg={6}>
							<Input label={withAsterisk(label.surname)} name="LastName" />
						</Column>
						<Column lg={10} offset={{lg: 1}}>
							<Checkbox label={newsletterText} name="Newsletter" />
						</Column>
					</FormRow>
				</SectionStyled>
				<ButtonAreaStyled>
					<Button isSubmitting={formikProps.isSubmitting} type="submit" text="Zum Profil speichern" />
				</ButtonAreaStyled>
			</>
		)}
	/>
);

PersonalData.propTypes = {
	onSubmit: PropTypes.func.isRequired,
	user: PropTypes.shape({
		UserId: PropTypes.string,
		FirstName: PropTypes.string,
		LastName: PropTypes.string,
		Newsletter: PropTypes.bool,
	}).isRequired,
};

const mapStateToProps = (state) => ({
	user: state.userManagement && state.userManagement.user,
});

const mapDispatchToProps = (dispatch) => ({
	onSubmit: (data) => dispatch(userDataUpdate(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PersonalData);
