/* eslint-disable camelcase */

import React from 'react';
import {graphql, StaticQuery} from 'gatsby';
import PropTypes from 'prop-types';
import Link from '../../../../Elements/Link/Link';
import Product from '../../../ProductInformation/Product/Product';
import VectorBlock from './Elements/VectorBlock';

import avonex_pen from '../../../../../assets/img/icons/avonex_pen.png';
import infusion from '../../../../../assets/img/icons/infusion.png';
import kapseln from '../../../../../assets/img/icons/kapseln.png';
import plegridy_pen from '../../../../../assets/img/icons/plegridy_pen.png';
import spritze from '../../../../../assets/img/icons/spritze.png';
import tabletten from '../../../../../assets/img/icons/tabletten.png';

import {InfoContainer, IconBoxStyled, LinkBlock, ProductContainerStyled, VectorApplicationContainer} from './styles';
import Headline from '../../../../Elements/Headline/Headline';
import Row from '../../../../Layout/Grid/Row';
import Column from '../../../../Layout/Grid/Column';

const PZN = ({pzn}) => (
	<InfoContainer>
		<StaticQuery
			query={graphql`
				query {
					allWordpressWpProductinfo {
						edges {
							node {
								slug
								acf {
									name
									strength
									vector
									prescription
									substance
									icon
									usage_info {
										link {
											url
											title
										}
									}
								}
							}
						}
					}
				}
			`}
			render={data => {
				const actualProduct = data.allWordpressWpProductinfo.edges.filter(
					ele => ele.node.acf.substance === pzn.name
				);

				const {name, strength, vector, prescription, substance, usage_info} = actualProduct[0].node.acf;
				const vectors = vector.split(' / ');
				const vectorArray = [];

				for (let i = 0; i < vectors.length; i += 1) {
					if (vectors[i] === 'Infusionslösung') {
						vectorArray.push(
							<VectorApplicationContainer>
								<img src={infusion} alt="Bild einer Infusionslösung" />
								<p>Infusionslösung</p>
							</VectorApplicationContainer>
						);
					} else if (vectors[i] === 'MR Tablette') {
						vectorArray.push(
							<VectorApplicationContainer>
								<img src={tabletten} alt="Bild einer Tablette" />
								<p>MR Tablette</p>
							</VectorApplicationContainer>
						);
					} else if (vectors[i] === 'Retardtabletten') {
						vectorArray.push(
							<VectorApplicationContainer>
								<img src={tabletten} alt="Bild einer Retard-Tablette" />
								<p>Retardtabletten</p>
							</VectorApplicationContainer>
						);
					} else if (vectors[i] === 'Fertigpen' && name === 'Avonex') {
						vectorArray.push(
							<VectorApplicationContainer>
								<img src={avonex_pen} alt="Bild eines Fertigpens" />
								<p>Fertigpen</p>
							</VectorApplicationContainer>
						);
					} else if (vectors[i] === 'Fertigpen' && name === 'Plegridy') {
						vectorArray.push(
							<VectorApplicationContainer>
								<img src={plegridy_pen} alt="Bild eines Fertigpens" />
								<p>Fertigpen</p>
							</VectorApplicationContainer>
						);
					} else if (vectors[i] === 'Fertigspritze') {
						vectorArray.push(
							<VectorApplicationContainer>
								<img src={spritze} alt="Bild einer Fertigspritze" />
								<p>Fertigspritze</p>
							</VectorApplicationContainer>
						);
					} else if (vectors[i] === 'Hartkapsel') {
						vectorArray.push(
							<VectorApplicationContainer>
								<img src={kapseln} alt="Bild einer Hartkapsel" />
								<p>Hartkapsel</p>
							</VectorApplicationContainer>
						);
					}
				}

				return (
					<>
						<Row>
							<Column lg={6}>
								<ProductContainerStyled>
									<Product
										name={name}
										strength={strength}
										prescription={prescription}
										substance={substance}
										usage_info={usage_info}
									/>
								</ProductContainerStyled>
							</Column>
							<Column lg={6}>
								<Headline as="h3" text="Darreichungsformen" />
								<VectorBlock>{vectorArray.map(el => el)}</VectorBlock>
								<LinkBlock>
									<Link to={`/service/ms-behandlung/${pzn.slug}`}>
										<IconBoxStyled
											theme="service"
											iconName="therapie"
											iconType="teaser"
											title="Therapieinformationen"
											boxType="tiny"
										/>
									</Link>
								</LinkBlock>
							</Column>
						</Row>
					</>
				);
			}}
		/>
	</InfoContainer>
);

PZN.propTypes = {
	pzn: PropTypes.shape({name: PropTypes.string, slug: PropTypes.string})
};

PZN.defaultProps = {
	pzn: null
};

export default PZN;
