import React from 'react';
import PropTypes from 'prop-types';
import TabSelection from '../TabsSelection/TabSelection';
import {ObserveModificationConsumer} from '../../../context/observeModificationContext';
import {TabContainerStyled, TabStyled} from './styles';

/**
 * @component TabsOnDashboard, shows tabs for selection, and the selected content
 * @arg tabItems: list of components that form the tabs, which can be selected
 * @arg children: components that form the content, only one of which is shown depending of selected tabItem
 */

const TabsOnDashboard = ({tabItems, children, state}) => {
	let indexSelected = 0;
	if (state && state.indexSelected) {
		indexSelected = state.indexSelected;
	}
	return (
		<ObserveModificationConsumer>
			{(context) => (
				<TabSelection
					bodyItems={React.Children.toArray(children)}
					onTabChangeRequest={context.onTabChangeRequest}
					tabItems={tabItems}
					indexSelected={indexSelected}
					render={(selectedTabItems, selectedBodyItem) => (
						<>
							<TabContainerStyled>
								{selectedTabItems.map((tabItem, index) => {
									return (
										// we can suppress the next eslint issue, since tabItems is expected never to change:
										// eslint-disable-next-line react/no-array-index-key
										<TabStyled key={index}>{tabItem}</TabStyled>
									);
								})}
							</TabContainerStyled>
							<>{selectedBodyItem}</>
						</>
					)}
				/>
			)}
		</ObserveModificationConsumer>
	);
};

TabsOnDashboard.propTypes = {
	children: PropTypes.node,
	tabItems: PropTypes.arrayOf(PropTypes.element),
	state: PropTypes.shape({indexSelected: PropTypes.number}),
};

TabsOnDashboard.defaultProps = {
	children: null,
	state: null,
	tabItems: undefined,
};

export default TabsOnDashboard;
