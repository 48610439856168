import React from 'react';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Row from '../../../../../../Layout/Grid/Row';
import Column from '../../../../../../Layout/Grid/Column';
import {Input} from '../../../../../../Elements/Form/InputAndTextarea/InputAndTextarea';
import {SubHeadlineStyled, SectionStyled, InfoboxStyled} from '../../../../../Register/styles';
import {PasswordInput} from '../../../../../../Elements/Form/InputAndTextarea/PasswordInput';
import FormRow from '../../../../../../Elements/Form/FormRow/FormRow';
import {forms} from '../../../../../../../assets/language/de';
import {withAsterisk, overwriteInitialValues} from '../../../../../../../utils/formHelpers';
import {userDataUpdate} from '../../../../../../../state/actions/userManagement';
import SubmitForm from '../../../../../SubmitForm/SubmitForm';
import Button from '../../../../../../Elements/Button/Button';
import {ButtonAreaStyled} from '../../styles';

const {label, validationMessages} = forms.Register;

const validationSchema = Yup.object().shape({
	UserName: Yup.string()
		.min(2, validationMessages.userName.ifTooShort)
		.max(50, validationMessages.userName.ifTooLong),
	Password1: Yup.string()
		.min(8, validationMessages.password.ifTooShort)
		.max(50, validationMessages.password.ifTooLong),
	Password2: Yup.string().oneOf([Yup.ref('Password1'), null], validationMessages.passwordConfirm.ifNoMatch),
});

const initialValuesForAccessData = {
	UserName: '',
	Password1: '',
	Password2: '',
};

const AccessData = ({submitNewUserData, user}) => {
	return (
		<SubmitForm
			initialValues={overwriteInitialValues(initialValuesForAccessData, user)}
			validationSchema={validationSchema}
			onSubmit={submitNewUserData}
			renderInnerForm={(formikProps) => (
				<>
					<SectionStyled>
						<Row styles={{alignItems: 'flex-end'}}>
							<Column md={6}>
								<>
									<SubHeadlineStyled>Zugangsdaten</SubHeadlineStyled>
									<FormRow>
										<Column>
											<Input label={withAsterisk(label.userName)} name="UserName" />
										</Column>
									</FormRow>

									<FormRow>
										<Column>
											<PasswordInput label={withAsterisk(label.newPassword)} name="Password1" />
										</Column>
									</FormRow>

									<FormRow>
										<Column>
											<PasswordInput
												label={withAsterisk(label.newPasswordConfirm)}
												name="Password2"
											/>
										</Column>
									</FormRow>
								</>
							</Column>
							<Column md={6}>
								<FormRow>
									<Column>
										<InfoboxStyled
											className="ap-register-info"
											theme="colored"
											icon="community"
											text="<h3>Hinweis</h3><p>Bitte beachte, dass von dir verfasste Beiträge unter dem von dir gewählten Benutzernamen in den Foren erscheinen und von Suchmaschinen gefunden werden können.</p>"
										/>
									</Column>
								</FormRow>
							</Column>
						</Row>
					</SectionStyled>
					<ButtonAreaStyled>
						<Button isSubmitting={formikProps.isSubmitting} type="submit" text="Zum Profil speichern" />
					</ButtonAreaStyled>
				</>
			)}
		/>
	);
};

AccessData.propTypes = {
	user: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
	submitNewUserData: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
	user: state.userManagement && state.userManagement.user,
});

const mapDispatchToProps = (dispatch) => ({
	submitNewUserData: (data) => dispatch(userDataUpdate(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AccessData);
